import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import {ToastrModule} from 'ngx-toastr';
import { MatTableExporterModule } from 'mat-table-exporter';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppComponent} from './app.component';
// Import containers
import {DefaultLayoutComponent} from './containers';
import {UnauthorizedInterceptor} from './services/auth/unauthorized.interceptor';
import { P401Component } from './views/error/401.component';
import {P403Component} from './views/error/403.component';
import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {AuthComponent} from './views/auth/auth.component';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule} from '@coreui/angular';
// Import routing module
import {routes} from './app.routing';
// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {UiSwitchModule} from 'ngx-ui-switch';
import { RouterModule } from '@angular/router';
import { NgMultiGatewayPaymentsModule } from '@meterfeeder/multi-gateway-payments'
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  imports: [
    BrowserModule,
    // StorageModule.forRoot({
    //   LSPrefix: 'meterfeeder-admin', IDBDBName: 'meterfeeder-admin_ngStorage'
    // }),
    CommonModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    UiSwitchModule,
    TabsModule.forRoot(),
    HttpClientModule,
    FormsModule,
    PerfectScrollbarModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    NgMultiGatewayPaymentsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatTableExporterModule,
  ],
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    P401Component,
    P403Component,
    P404Component,
    P500Component,
    LoginComponent,
    AuthComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
