import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import {Logger} from './logger/logger.service';

const Log = new Logger({service: 'ErrorService'});

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private notify: ToastrService
  ) {}

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'Operation', result?: T) {
    return (context: any): Observable<any> => {

      let message: string;
      let details: any = {};
      if (isHttpErrorResponse(context)) {
        details = {
          req_id: context.headers.get('Request-Id'), // Retain spelling from restify logger, server side
          subject: `Admin error, ${operation} Failed, ${context.statusText}`,
          url: context.url,
        };
        if (isProgressEvent(context.error) || context.error === null) {
          message = context.message;
          details.error = new Error(message);
        } else if (context.error && context.error.error) {
          message = context.error.error.message;
          details.error = context.error.error;
        } else if (context.error.message === 'Validation Failed') {
          message = context.error.message;
          details.error = context.error.details;
        } else {
          message = 'Unexpected Error';
          details.error = new Error(message);
        }
      } else if (isError(context)) {
        message = context.message;
        details = {
          error: context,
          subject: `Admin error, ${message}`
        };
      } else if (isError(context.error)) {
        message = context.error.message;
        details = {
          error: context.error,
          subject: `Admin error, ${message}`
        };
      } else if (typeof context === 'string') {
        message = context;
        details = {
          error: new Error(context),
          subject: `Admin error, ${message}`
        };
      } else {
        message = 'Unknown Error';
        details = {
          error: new Error(message),
          subject: `Admin error, ${message}`
        };
      }
      try {
      details.error = JSON.parse(JSON.stringify(details.error)); // Strip out circular dependency.
      } catch(e) {
        Log.error(e, 'Error parsing JSON');
      }
      this.notify.error(message, `${operation} Failed`);

      if (message.match(/Http failure response/)) {
        message = 'API Endpoint Unreachable';
        Log.fatal(details, message);
      } else {
        Log.error(details, message);
      }
      if (result === undefined && details.error) {
        result = details.error;
      }

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  public setSessionId(sessionId: string) {
    Log.setSessionId(sessionId);
  }
}

export function isError(subject: any): subject is Error {
  return subject instanceof Error;
}

export function isHttpErrorResponse(subject: any): subject is HttpErrorResponse {
  return subject && subject.name === 'HttpErrorResponse';
}

export function isProgressEvent(subject: any): subject is ProgressEvent {
  return subject && subject instanceof ProgressEvent;
}

