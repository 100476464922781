import {Component, isDevMode, OnInit, ViewEncapsulation} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as LogRocket from 'logrocket';
import {environment} from '../environments/environment';
import { AuthService } from './services/auth/auth.service';

// This initializes log rocket
if (environment.production) { 
  LogRocket.init('fdm4sx/managemeterfeeder', {
    network: {
      requestSanitizer: (request: {url: string}) => {
        // if the url contains 'heartland'
        if (request.url.toLowerCase().indexOf('heartlandportico') !== -1) {
          // set the url to 'heartland'
          request.url = 'heartlandportico';
          return request;
        }

        // otherwise log the request normally
        return request;
      },
    },
  }); 
}

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>\n',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // // Refetch the Auth0 profile for changes to the client:
      // this.auth.getUserProfile
      window.scrollTo(0, 0);
    });
  }
}
