import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
// import * as auth0 from 'auth0-js';
// import {Auth0DecodedHash, Auth0UserProfile} from 'auth0-js';
import { Auth0Client, IdToken, RedirectLoginResult, createAuth0Client } from '@auth0/auth0-spa-js';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {User} from '../../models/user.model';
import * as LogRocket from 'logrocket';
import { Name } from '../../models/name.model';
import { isObjectId } from '../../util';
import { ErrorService } from '../error.service';

interface GetUserResponse {
  user: User;
}

export interface UserToken extends IdToken {
  user_metadata: any;
  app_metadata: {
    authorization: {
      groups: string[];
      roles: string[];
      permissions: string[];
    };
    profile: {
      type: 'meter-feeder-client';
      _id: string;
      name: Name;
      email: string;
      createdAt: Date;
      updatedAt: Date;
      username: string;
      client: string;
    };
    sub: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth0?: Auth0Client; 

  get token_content(): UserToken {
    const profile = localStorage.getItem('profile');
    if (profile) {
      return JSON.parse(profile);
    }
    throw new Error('no profile in localstorage')
  }

  get accessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private error: ErrorService
  ) { 
    
  }

  public async createAuth0Client(): Promise<Auth0Client> {
    this.auth0 = await createAuth0Client({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        scope: environment.scope,
        audience: environment.audience,
        redirect_uri: environment.redirect_uri
      }
    })
    return this.auth0;
  }

  /**
   * Test the if the current user profile allows for the provided permission.
   * @param parts
   */
  public can(...parts: string[]): boolean {
    try {
      const permission = parts.join(':');
      const allowed = this.token_content?.app_metadata.authorization.permissions;
      return allowed && allowed.indexOf(permission) !== -1;
    } catch (e) {
      return false;
    }
  }

  /**
   * Helper function to make an authenticated HTTP GET request to the API server.
   *
   * @param url
   * @param params
   */
  public get<T>(url: string, params?: HttpParams): Observable<T> {
    if (this.accessToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
      return this.http.get<T>(environment.apiUrl + url, {
        headers,
        params
      });
    }
    return throwError('No access token');
  }

  /**
   * Helper function to make an authenticated HTTP POST request to the API server.
   *
   * @param url
   * @param data
   */
  public patch<T, R>(url: string, data: R): Observable<T> {
    if (this.accessToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
      return this.http.patch<T>(environment.apiUrl + url, data, {
        headers: headers
      });
    }
    return throwError('No access token');
  }

  /**
   * Helper function to make an authenticated HTTP POST request to the API server.
   *
   * @param url
   * @param data
   */
  public post<T, R>(url: string, data: R): Observable<T> {
    if (this.accessToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
      return this.http.post<T>(environment.apiUrl + url, data, {
        headers: headers
      });
    }
    return throwError('No access token');
  }

  /**
   * Helper function to make an authenticted HTTP PUT request to the API server.
   *
   * @param url
   * @param data
   */
  public put<T, R>(url: string, data: R): Observable<T> {
    if (this.accessToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
      return this.http.put<T>(environment.apiUrl + url, data, {
        headers: headers
      });
    }
    return throwError('No access token');
  }

  /**
   * Helper function to make an authenticated HTTP DELETE request to the API server.
   *
   * @param url
   */
  public delete<T>(url: string): Observable<T> {
    if (this.accessToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
      return this.http.delete<T>(environment.apiUrl + url, {headers: headers});
    }
    return throwError('No access token');
  }

  /**
   * Use access token to retrieve user's account from Meter Feeder API.
   */
  public getUserAccount(): Observable<User | undefined> {
    return this.get<GetUserResponse>('/user/auth')
      .pipe(
        catchError(this.error.handleError('getUserAccount', null)),
        map((response: GetUserResponse | null) => {
          return response?.user;
        }),
        map(user => user ? new User(user) : undefined),
        tap(user => {
          if (user?.municipality) {
            const client: string = isObjectId(user.municipality) ? user.municipality : user.municipality.label;
            LogRocket.identify(this.token_content.sub, {client});
            this.error.setSessionId(this.token_content.sub);
          } else {
            LogRocket.identify(this.token_content.sub);
            this.error.setSessionId(this.token_content.sub);
          }
        })
      );
  }

  /**
   * Use access token to retrieve user's profile from auth0 and set session
   */
  public async getUserProfile(token: string): Promise<UserToken> {
    if (!this.auth0) {
      this.auth0 = await this.createAuth0Client();
    }
    let token_content = await this.auth0.getUser<UserToken>();
    if (token_content) {
      this.setSession(token, token_content);
      return token_content;
    } else {
      throw new Error('Could not fetch user from auth0');
    }
  }

  /**
   * Sets the User profile to LogRocket so tracking works
   */
  public setLogRocketInfo(token_content: UserToken) {
    if (environment.production) {
      LogRocket.identify(token_content.sub, {
        name: token_content.app_metadata.profile.name ? Object.values(token_content.app_metadata.profile.name).join(' ') : token_content.app_metadata.profile.username,
        email: token_content.app_metadata.profile.email,
        username: token_content.app_metadata.profile.username
      });
      this.error.setSessionId(token_content.sub);
    }
  }
  /**
   * Parses the auth_token from the url hash and sets values into local storage.
   */
  public async handleLoginCallback(): Promise<UserToken> {
    try {
      if (!this.auth0) {
        this.auth0 = await this.createAuth0Client();
      }
      let authResults: RedirectLoginResult = await this.auth0.handleRedirectCallback();
      console.log(authResults);
      return this.refreshToken();
    } catch (err: any) {
      if (err.error === 'invalid_token') {
        setTimeout(() => {
          this.router.navigateByUrl('/');
        }, 3000); // Show the message for 3 sec and then navigate to clear the error.
      }
      throw err;
    }
  }

  public async refreshToken() {
    if (!this.auth0) {
      this.auth0 = await this.createAuth0Client();
    }
    await this.auth0.checkSession();
    const token = await this.auth0.getTokenSilently({cacheMode: 'off'});
    return this.getUserProfile(token);
  }

  /**
   * Check status of the token in local storage.
   */
  public async isAuthenticated(): Promise<boolean> {
    if (!this.auth0) {
      this.auth0 = await this.createAuth0Client();
    }
    let auth = await this.auth0.isAuthenticated();
    if (auth) {
      return true;
    } else {
      await this.auth0.checkSession();
      return this.auth0.isAuthenticated();
    }
  }

  /**
   * Forwards user to the auth0 lock screen. Application is unloaded from the browser and will load again after authentication completes.
   */
  public async login(): Promise<void> {
    if (!this.auth0) {
      this.auth0 = await this.createAuth0Client();
    }
    return this.auth0.loginWithRedirect({
      authorizationParams: {
        scope: environment.scope,
        audience: environment.audience,
        redirect_uri: environment.redirect_uri
      }
    });
  }

  /**
   * Removes all the local storage keys (effective logout) and redirects to login screen.
   */
  public async logout(url: string = '/'): Promise<void> {
    if (!this.auth0) {
      this.auth0 = await this.createAuth0Client();
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('profile');
    localStorage.setItem('redirectUrl', url);

    this.auth0.logout({
      // async onRedirect(url) {
      //        window.location.replace(window.location.origin),
      // }, 
      logoutParams: {
        returnTo: window.location.origin, 
        federated: true,
      },
      clientId: environment.auth0.clientId,
    })
    this.login();

  }

  /**
   * Update the local storage information for the current login session.
   *
   * @param authResult
   * @param profile
   */
  private setSession(token: string, profile: UserToken): void {
    const reNamespace = new RegExp('^' + environment.namespace + '(.+)$');
    if (profile) {
      Object.keys(profile).forEach((key: string) => {
        const matches = key.match(reNamespace);
        if (matches) {
          profile[matches[1]] = profile[key];
        }
      });
    }
    localStorage.setItem('access_token', token);
    localStorage.setItem('profile', JSON.stringify(profile));
  }
}
