import { Role } from './role.model';
import { Group } from './group.model';
import { Name } from './name.model';
import { IClient } from '../services/client/client.service';
import { IZone } from './zone.model';
import { isObjectId } from '../util';

export class User {
  _id: string;
  auth0: {
    sub?: string;
    providers?: {
      [key: string]: any;
    };
  };
  badge: string;
  config: {
    reader: any;
    admin: any;
    ticket: any;
  };
  created?: Date;
  email?: string;
  groups?: Group[];
  municipality?: IClient | string;
  name: Name = new Name();
  owner: (string | IClient | null)[];
  roles?: Role[];
  status: string;
  username: string;
  updatedAt?: Date;
  zone?: IZone | string;

  constructor(input: any) {
    this._id = input._id;
    this.auth0 = input.auth0;
    this.badge = input.badge;
    this.config = input.config;
    this.email = input.email;
    this.groups = input.groups;
    this.municipality = input.municipality;
    this.name = new Name(input.name);
    this.owner = input.owner;
    this.roles = input.roles;
    this.status = input.status;
    this.username = input.username;
    this.zone = input.zone;
    this.created = input.created && new Date(input.created);
    this.updatedAt = input.updatedAt && new Date(input.updatedAt);
  }

  public hasIntegration(key: string) {
    let has = false;
    if (this.municipality && (!isObjectId(this.municipality))) {
      try {
        has = this.municipality.integrations[key].active;
      } catch (e) {
        has = false;
      }
    }
    return has;
  }

  public get hasAuth0(): boolean {
    return !!(this.auth0 && this.auth0.sub);
  }
}

export enum PermissionNouns {
  Users = 'users',
}

export enum PermissionVerbs {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
  View = 'view',
}

// static edit(currentUser: User, subject: User, verb: PermissionVerbs): boolean {
//   return currentUser._id === subject._id;
// }
