import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  templateUrl: '401.component.html'
})
export class P401Component {

  constructor(
    private auth: AuthService
  ) { }

  logout() {
    return this.auth.logout()
  }
}
