import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import {AuthGuard} from './services/auth/auth.guard';
import {AuthComponent} from './views/auth/auth.component';
import { P401Component } from './views/error/401.component';
import {P403Component} from './views/error/403.component';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

export const routes: Routes = [
  {
    path: 'callback',
    component: AuthComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'unauthorized',
        component: P403Component
      },
      {
        path: 'no-permissions',
        component: P401Component
      },
      {
        path: 'parking-transactions',
        loadChildren: () => import('./views/parking-transactions/parking-transaction.module')
          .then(m => m.ParkingTransactionModule)
      },
      {
        path: 'tickets',
        loadChildren: () => import('./views/tickets/ticket.module').then(m => m.TicketModule)
      },
      {
        path: 'leases',
        loadChildren: () => import('./views/leases/lease.module').then(m => m.LeaseModule)
      },
      {
        path: 'enforcement',
        loadChildren: () => import('./views/enforcement/enforcement.module').then(m => m.EnforcementModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'zones',
        loadChildren: () => import('./views/zones/zones.module').then(m => m.ZonesModule)
      },
      {
        path: 'customer',
        loadChildren: () => import('./views/customer/customer.module').then(m => m.CustomerModule)
      }
    ]
  }
];

