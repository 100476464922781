import {Injectable} from '@angular/core';

import {LogglyTracker} from 'loggly-jslogger';
import {environment} from '../../../environments/environment';

export enum LogLevels {
  fatal = 60,
  error = 50,
  warn = 40,
  info = 30,
  debug = 20,
  trace = 10,
}

// loggly tracker is a private singleton
const TRACKER: LogglyTracker = new LogglyTracker();
TRACKER.push({
  logglyKey: environment.loggly.key,
  sendConsoleErrors: true,
  tag: 'manage.meterfeeder.com',
});

export class Logger {
  private logLevel: LogLevels = LogLevels.info;

  constructor(
    private defaults: any,
  ) {}

  public fatal(details: any, msg: string) {
    this.log(details, msg, LogLevels.fatal);
  }

  public error(details: any, msg: string) {
    this.log(details, msg, LogLevels.error);
  }

  public warn(details: any, msg: string) {
    this.log(details, msg, LogLevels.warn);
  }

  public info(details: any, msg: string) {
    this.log(details, msg, LogLevels.info);
  }

  public debug(details: any, msg: string) {
    this.log(details, msg, LogLevels.debug);
  }

  public trace(details: any, msg: string) {
    this.log(details, msg, LogLevels.trace);
  }

  public child(additional: any) {
    const defaults = {
      defaults: this.defaults,
      additional,
    };
    return new Logger(defaults);
  }

  public setSessionId(sessionId: string) {
    TRACKER.setSession(sessionId);
  }

  private log(details: any, msg: string, level: LogLevels) {
    if (typeof details === 'string') {
      if (!msg) {
        msg = details;
        details = {};
      } else {
        // has to be a object
        details = {details};
      }
    }
    const logDetail = {
      time: new Date().toISOString(),
      level,
      ...this.defaults,
      ...details,
    };
    console.warn(msg, logDetail);
    if (this.logLevel <= level) {
      TRACKER.push({msg, ...logDetail});
    }
  }
}
