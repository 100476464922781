<div class="app flex-row align-items-center pace-done">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <div *ngIf="error" class="alert-danger alert">
            <h1 class="float-left display-3 mr-4">!</h1>
            <h4 class="pt-3">{{error.title}}</h4>
            <p class="text-muted">{{error.message}}</p>
            <p class=""><a href="/">Click here to try again</a></p>
          </div>
        </div>
        <div *ngIf="!error" class="">
          <h4 class="pt-3">Logging you in.</h4>
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
                 aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
