export class Name {
    get full(): string {
      return [this.first, this.last].join(' ');
    }
    public first: string = '';
    public last: string = '';
    
    constructor(input?: {first?: string, last?: string}) {
      if (!input) {
        return;
      }
        this.first = input.first || '';
        this.last = input.last || '';
    }
  
    public isSet(): boolean {
      if (!this.first && !this.last) {
        return false;
      }
      return true;
    }
  }
  