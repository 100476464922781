import { AuthService } from './services/auth/auth.service';

export const navItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cui-dashboard',
  },
  {
    title: true,
    name: 'Reports'
  },
  {
    name: 'Parking Transactions',
    url: '/parking-transactions',
    icon: 'icon-location-pin',
    permission: 'parking:view',
  },
  {
    name: 'Tickets',
    url: '/tickets',
    icon: 'icon-notebook',
    permission: 'ticket:view',
  },
  // {
  //   name: 'Leases',
  //   url: '/leases',
  //   icon: 'fa fa-stack-overflow',
  //   permission: 'permits:view',
  //   children: [
  //     {
  //       name: 'Plans',
  //       url: '/leases/plans',
  //       permission: 'permits:view',
  //       icon: 'fa fa-file'
  //     },
  //     {
  //       name: 'Permits',
  //       url: '/leases/permits',
  //       permission: 'permits:view',
  //       icon: 'fa fa-address-card'
  //     },
  //   ]
  // },
  {
    name: 'Permits',
    url: '/leases/plans',
    permission: 'permits:view',
    icon: 'fa fa-file'
  },
  // {
  //   name: 'Permits',
  //   url: '/leases/permits',
  //   permission: 'permits:view',
  //   icon: 'fa fa-address-card'
  // },
  // {
  //   name: 'Revenue',
  //   url: '/theme/typography',
  //   icon: 'icon-graph',
  //   permission: 'revenue:view',
  // },
  // {
  //   name: 'Curb Management',
  //   url: '/theme/typography',
  //   icon: 'icon-pencil'
  // },
  {
    title: true,
    name: 'Integrations'
  },
  {
    name: 'Enforcement',
    url: '/base',
    icon: 'icon-check',
    children: [
      {
        name: 'Zones',
        url: '/zones',
        icon: 'icon-map',
        permission: 'zones:view',
      },
      {
        name: 'Codes',
        url: '/enforcement/codes',
        icon: 'icon-list',
        permission: 'codes:view',
      },
      {
        name: 'Notifications',
        url: '/enforcement/notifications',
        icon: 'fa fa-commenting',
        permission: 'enforcement:notification:view',
      },
      {
        name: 'Events',
        url: '/enforcement/events',
        icon: 'icon-calendar',
        permission: 'enforcement:events:view',
      },
      // {
      //   name: 'Hours',
      //   url: '/base/carousels',
      //   icon: 'icon-calendar'
      // },
      // {
      //   name: 'Prices',
      //   url: '/base/collapses',
      //   icon: 'cui-dollar'
      // }
    ]
  },
  // {
  //   name: 'Valet',
  //   url: '/base',
  //   icon: 'icon-key'
  // },
  // {
  //   name: 'Garage',
  //   url: '/base',
  //   icon: 'icon-home'
  // },
  {
    divider: true
  },
  {
    title: true,
    name: 'Administrator',
  },
  {
    name: 'Users',
    url: '/users',
    icon: 'icon-user',
    permissions: 'users:view',
  },
  // {
  //   title: true,
  //   name: 'Order',
  // },
  // {
  //   name: 'Enforcement kits',
  //   url: '/pages',
  //   icon: 'cui-tablet'
  // },
  // {
  //   name: 'Paper',
  //   url: '/pages',
  //   icon: 'cui-selectGroup'
  // }
];

export function getNavStructure(auth: AuthService, integrations: any) {
  return navItems.filter((item: any) => {
    try {
      if (!item.permission) {
        return true;
      }
      const noun = item.permission.split(':')[0];
      if (integrations[noun].active) {
        return auth.can(item.permission);
      }
      return false;
    } catch (e) {
      return false;
    }
  });
}
