import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Auth0UserProfile} from 'auth0-js';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  public user?: Auth0UserProfile;
  public error: any = null;

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
  }

  public async ngOnInit() {
    try {
      let token_content = await this.auth.handleLoginCallback();
      this.auth.setLogRocketInfo(token_content);
      const url: string = localStorage.getItem('redirectUrl') || '/';
      this.router.navigateByUrl(url);
    } catch (err: any) {
      this.error = {
        title: 'Unexpected Error',
        message: err.errorDescription || err.message
      };
      console.error(err)
      switch (err.error) {
        case 'access_denied':
          this.error.title = 'Access Denied';
          break;
        case 'unauthorized':
          this.error.title = 'Could not auth your account';
          this.error.message += ' (retrying in 5 seconds)';
          setTimeout(() => {
            this.router.navigateByUrl('/');
          }, 5000);
          break;
      }
    }
  }
}
