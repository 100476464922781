import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private tempData: number[] = [];
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return from(this.auth.isAuthenticated())
      .pipe(
        map((authenticated: boolean) => {
          if (authenticated) {
            return true;
          }

          // not logged in save state and redirect to login page with the return url
          // localStorage.setItem('redirectUrl', state.url);
          this.auth.login();
          return false;
        })
      );
  }
}
