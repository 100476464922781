<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">401</h1>
          <h4 class="pt-3">Session Expired?</h4>
          <p class="text-muted">You do not have permission to view this page, or your session expired</p>
          <button (click)="logout()">Log back in</button>
        </div>
      </div>
    </div>
  </div>
</div>
