import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { isObjectId } from '../../util';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth/auth.service';
import { navItems } from '../../_nav';
import { Logger } from '../../services/logger/logger.service';
import { IClient } from '../../services/client/client.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

const Log = new Logger({ component: 'DefaultLayoutComponent' });

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styles: [`
    #wrapAdBlock {
      position: fixed;
      right: 10px;
      bottom: 0;
    }
  `]
})
export class DefaultLayoutComponent implements AfterViewInit, OnInit, OnDestroy {
  public adBlockerPresent = false;
  public showAdBlockAlert = true;
  public clients: IClient[] = [];
  public isAdmin = false;
  public selectedClient?: string;
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public year = new Date().getFullYear();
  public version = environment.version;
  constructor(
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    Log.debug({}, 'loading component');
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  selectClient(evt: Event) {
    const id = (<HTMLFormElement>evt.target).value;
    this.auth.patch('/client/set', { id: id })
      .subscribe(() => {
        let current = window.location.pathname + window.location.search;
        // this.auth.logout(current);
        localStorage.setItem('redirectUrl', current);
        this.auth.refreshToken()
        .then(() => this.router.navigateByUrl('/dashboard'));
      }, () => this.auth.logout('/'));
  }

  ngAfterViewInit(): void {
    /* tslint:disable:triple-equals */
    // height may be 0 or undefined.
    if ($('#wrapAdBlock .adBanner').height() == 0) {
      Log.warn({}, 'AdBlocker Detected');
      this.adBlockerPresent = true;
      this.cdr.detectChanges();
    }
    /* tslint:enable:triple-equals */
  }

  ngOnInit() {
    if (this.auth.token_content) {
      if (this.auth.token_content.app_metadata.authorization.roles.indexOf('admin') !== -1) {
        this.isAdmin = true;
      }
      this.auth.get<{ client: IClient[] }>('/client')
        .subscribe((results: { client: IClient[] }) => {
          if (results.client && results.client.length > 1) {
            this.clients = results.client;
          }
        });
      this.auth.getUserAccount()
        .subscribe((user: User | undefined) => {
          if (user?.municipality) {
            if (isObjectId(user.municipality)) {
              this.selectedClient = user.municipality;
            } else {
              this.selectedClient = user.municipality._id;
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  public logout() {
    this.auth.logout();
  }

  public dismissAdBlockAlert() {
    this.showAdBlockAlert = false;
  }
}
