<app-header
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo.png', width: 140, alt: 'Meter Feeder Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/sygnet.png', width: 30, alt: 'Meter Feeder Logo'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false">
  <div class="navbar-nav ml-auto">
    <div class="form-inline my-2 my-lg-0">
      <select *ngIf="clients" name="clientChanger" id="clientChanger" [(ngModel)]="selectedClient" (change)="selectClient($event)" class="form-control pull-right">
        <option value="" disabled>All Clients</option>
        <option *ngFor="let client of clients" [value]="client._id">{{client.label}}</option>
      </select>
      <ul class="nav navbar-nav">
        <li class="nav-item dropdown" dropdown placement="bottom right">
          <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
            <i class="icon-settings"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
            <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <app-breadcrumb></app-breadcrumb>
    </ol>
    <!-- /Breadcrumb -->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>
<app-footer>
  <span><a target="_blank" href="https://meterfeeder.com">Meter Feeder</a> &copy; {{year}} | Version: {{version}}</span>
  <span class="ml-auto"></span>
</app-footer>

<div id="wrapAdBlock" class="breadcrumb-menu d-md-down-none">
  <div class="adBanner">&nbsp;</div>
  <div class="alert alert-warning message" *ngIf="adBlockerPresent && showAdBlockAlert">
    <button (click)="dismissAdBlockAlert()" type="button" class="close ml-3" data-dismiss="alert">&times;</button>
    We respect your using an Ad Blocker, but please disable it on this site so we can monitor errors and improve your experience
  </div>
</div>
