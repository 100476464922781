import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from './auth.service';
import * as LogRocket from 'logrocket';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  /**
   * Catches 401 errors on Http requests and if found call logout to remove local storage and reload the page.
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: any) => {

      // User needs to login. Their session may have expired
      if (err.status === 401) {
        this.router.navigate(['/no-permissions']).then();
        // this.auth.logout();
      }
      // User does not have access to this content.
      if (err.status === 403) {
        this.router.navigate(['/unauthorized']).then();
      }
      return throwError(err);
    }));
  }
}
