// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { IProjectEnvironment } from "./interface";

export const environment: IProjectEnvironment = {
  apiUrl: 'https://api.dev.meterfeeder.com',
  versionLabel: 'ma-version',
  version: '5.10.2-demo',
  production: false,
  namespace: 'https://meterfeeder.com/',
  scope: 'openid email profile',
  audience: 'http://develop.test.meterfeeederapp.com',
  redirect_uri: window.location.origin + '/callback',
  auth0: {
    domain: 'mfdev.auth0.com',
    clientId: '4bi37TSj76qv5pizgNSiNlgtydf0LyuI',
  },
  cardConnectUrl: 'https://boltgw-uat.cardconnect.com',
  googleMapsKey: 'AIzaSyCdwfiryNTQe8tALJGr1Sfwi56ChQCFRaQ',
  loggly: {
    key: '',
    name: 'manage-app',
  },
  hpsPublicKey: 'pkapi_cert_oLzEg7v3NqX5XujUEv',
  hpsTokenUrl: 'https://cert.api2.heartlandportico.com/Hps.Exchange.PosGateway.Hpf.v1/api/token',
  userAdminUrl: 'https://user-admin.dev.meterfeeder.com',
};
